
.center-image {
    margin: 0 auto;
    display: block;
}

.image-border {
    border-width: 1px;
    border-color: gray;
    border-style: solid;
}

.badge-list-item {
  height: 64px;
  width: 64px;
  float: left;
  overflow: auto;
  // display: block;
  // margin-left: auto;
  // margin-right: auto;
  // float: left;
  padding: 10px;
}

// Custom table style for the getting started page
td.getting-started-cell-screenshot {
  width: 100px;
  text-align: center;
  border-spacing: 1px;
  margin: 1px;
  padding: 1px;
}

td.getting-started-cell-desktop-screenshot {
  width: 200px;
  text-align: center;
  border-spacing: 1px;
  margin: 1px;
  padding: 1px;
}
